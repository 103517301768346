import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['main']);

const routes: Routes = [
  {
    path: 'main',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems },
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'tab-record-class',
    loadChildren: () => import('./tab-record-class/tab-record-class.module').then( m => m.TabRecordClassPageModule)
  },
  {
    path: 'tab-record-class-create',
    loadChildren: () => import('./tab-record-class-create/tab-record-class-create.module').then( m => m.TabRecordClassCreatePageModule)
  },
  {
    path: 'tab-record-class-note',
    loadChildren: () => import('./tab-record-class-note/tab-record-class-note.module').then( m => m.TabRecordClassNotePageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
